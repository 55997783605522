<template>
  <div>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group>
              <h6 class="text-primary">
                No. Poste
              </h6>
              <b-form-input
                v-model="aplomadoPoste.noPoste"
                :disabled="editable"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group>
              <h6 class="text-primary">
                Fecha
              </h6>
              <b-form-input
                v-model="aplomadoPoste.fecha"
                :disabled="editable"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group>
              <h6 class="text-primary">
                Brigada
              </h6>
              <b-form-input
                v-model="aplomadoPoste.brigada"
                :disabled="editable"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="12"
          >
            <b-form-group>
              <h6 class="text-primary">
                Lugar
              </h6>
              <b-form-input
                v-model="aplomadoPoste.lugar"
                :disabled="editable"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <h3 class="text-primary">
          Trabajo Realizado
          <hr>
        </h3>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <h6 class="text-primary">
                Desmontaje
              </h6>
              <v-select
                v-model="aplomadoPoste.desmontaje"
                label="nombre"
                :disabled="editable"
                :options="desmontaje"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <h6 class="text-primary">
                Montaje
              </h6>
              <v-select
                v-model="aplomadoPoste.montaje"
                label="nombre"
                :disabled="editable"
                :options="montaje"
              />
            </b-form-group>
          </b-col>
          <!-- BOTONES -->
          <b-col
            v-if="editable"
            cols="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="changeEditable"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Editar</span>
            </b-button>
          </b-col>
          <b-col
            v-if="!editable"
            cols="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateItem"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Guardar</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="cancel"
            >
              <feather-icon
                icon="SlashIcon"
                class="mr-50"
              />
              <span class="align-middle">Cancelar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BCard, BButton, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getListadoAplomadoPoste } from '@/utils/mantenimiento/aplomadoPoste/aplomadoPosteUtils'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BFormInput,
    BFormGroup,
    vSelect,
    BButton,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      aplomadoPoste: {
        noPoste: '412',
        fecha: '15/02/2023',
        brigada: 'Brigada 1',
        lugar: '9 Avenida 4-90 Linda Vista Villa Nueva',
        desmontaje: 'Cable',
        montaje: 'Brazo',
      },
      desmontaje: [],
      montaje: [],
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
    }
  },
  async created() {
    this.desmontaje = getListadoAplomadoPoste(1)
    this.montaje = getListadoAplomadoPoste(2)
  },
  methods: {
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    async updateItem() {
      let result
    },
    async cancel() {
      if (
        router.currentRoute.params.action === 'Edit'
          || router.currentRoute.params.action === 'Create'
      ) await this.$router.replace('/aplomado-poste')
      else await this.cargarItem()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
